import * as Three from 'three';
import {loadObjWithMaterial} from '../../utils/load-obj';

import React from 'react';
import convert from 'convert-units';



let cachedPanel = null;
const STYLE_ARC_BASE = {stroke: '#000', strokeWidth: '3px', fill: 'none'};
const STYLE_ARC_SELECTED = {stroke: '#0096fd', strokeWidth: '4px', fill: 'none', cursor: 'move'};
const EPSILON = 3;

export default {
  name: "CornerPanelSet",
  prototype: "items",

  info: {
    title: "Corner Panel Set",
    tag: ['pens', 'panels'],
    description: "90 Degree Corner Panel Set",
    image: require('./CornerPanelSet.png')
  },

    properties: {
      width: {
        label: 'Width',
        type: 'length-measure',
        defaultValue: {
          length: 152.3,
          unit: 'mm',
        }
      },
      height: {
        label: 'Height',
        type: 'length-measure',
        defaultValue: {
          length: 162.5,
          unit: 'mm',
        }
      },
      altitude: {
        label: 'Altitude',
        type: 'length-measure',
        defaultValue: {
          length: 0
        }
      },
  },

  render2D: function (element, layer, scene) {
    let width = {length: 1523, unit: 'mm'};
    let depth = {length: 1625, unit: 'mm'};
    let innerWidth = {length: 1103, unit: 'mm'};
    let innerDepth = {length: 1205, unit: 'mm'};
    let holeWidth = element.properties.get('width').get('length');
    let arcPath = `M${0},${0}  A${holeWidth},${holeWidth} 0 0,1 ${holeWidth},${holeWidth}`;
    let arcStyle = element.selected ? STYLE_ARC_SELECTED : STYLE_ARC_BASE;
    let newWidth = convert(width.length).from(width.unit).to(scene.unit);
    let newDepth = convert(depth.length).from(depth.unit).to(scene.unit);

    let newinnerWidth = convert(innerWidth.length).from(width.unit).to(scene.unit);
    let newinnerDepth = convert(innerDepth.length).from(depth.unit).to(scene.unit);
    let innerarcPath = `M${0},${0}  A${newinnerWidth},${newinnerDepth} 0 0,1 ${newinnerWidth},${newinnerDepth}`;

    let angle = element.rotation + 90;
    let textRotation = 45;
    if (Math.sin(angle * Math.PI / 180) < 0) {
      textRotation = 180;
    }

    let style = {stroke: element.selected ? '#0096fd' : '#000', strokeWidth: "2px", fill: "#84e1ce"};
    let arrow_style = {stroke: element.selected ? '#0096fd' : null, strokeWidth: "2px", fill: "#84e1ce"};

    return (
      <g transform={`translate(${-newWidth / 2},${-newDepth / 2})`}>
       <path key="0" d={arcPath} style={arcStyle} transform={`translate(${0},${newDepth}) scale(${1},${-1.1}) rotate(${0})`}/>
       <path key="0" d={innerarcPath} style={arcStyle} transform={`translate(${0},${newinnerDepth}) scale(${1},${-1}) rotate(${0})`}/>
       <g transform={`translate(${newWidth},${newDepth}) rotate(${180})`}>
        <line key="2" x1={newWidth / 2} x2={newWidth / 2} y1={newDepth} y2={1.5 * newDepth} style={arrow_style}/>
        <line key="3" x1={.35 * newWidth} x2={newWidth / 2} y1={1.2 * newDepth} y2={1.5 * newDepth} style={arrow_style}/>
        <line key="4" x1={newWidth / 2} x2={.65 * newWidth} y1={1.5 * newDepth} y2={1.2 * newDepth} style={arrow_style}/></g>
        <text key="5" x="-12" y="-12" transform={`translate(${newWidth / 2}, ${newDepth / 2}) scale(1,-1) rotate(${textRotation})`}
              style={{textAnchor: "middle", fontSize: "11px"}}>
          {element.type}
        </text>
      </g>
    );
  },

  render3D: function (element, layer, scene) {
    let width = {length: 50, unit: 'mm'};
    let depth = {length: 950, unit: 'mm'};
    let height = {length: 1000, unit: 'mm'};

    let onLoadItem = (object) => {

      let newWidth = convert(width.length).from(width.unit).to(scene.unit);
      let newHeight = convert(height.length).from(height.unit).to(scene.unit);
      let newDepth = convert(depth.length).from(depth.unit).to(scene.unit);

      let newAltitude = element.properties.get('altitude').get('length');

      if (element.selected) {
        let box = new Three.BoxHelper(object, 0x99c3fb);
        box.material.linewidth = 2;
        box.material.depthTest = false;
        box.renderOrder = 1000;
        object.add(box);
      }

      object.scale.set(newWidth / width.length, newHeight / height.length, newDepth / depth.length);

      // Normalize the origin of the object
      let boundingBox = new Three.Box3().setFromObject(object);

      let center = [
        (boundingBox.max.x - boundingBox.min.x) / 2 + boundingBox.min.x,
        (boundingBox.max.y - boundingBox.min.y) / 2 + boundingBox.min.y,
        (boundingBox.max.z - boundingBox.min.z) / 2 + boundingBox.min.z];

      object.position.x -= center[0];
      object.position.y -= center[1] - (boundingBox.max.y - boundingBox.min.y) / 2;
      object.position.z -= center[2];

      object.position.y += newAltitude;
// rotate 90 degrees around the y-axis
    //  object.rotation.y = Math.PI / 2;
// rotate -90 degrees around the y-axis
    //  object.rotation.y = -Math.PI / 2;
object.rotation.y = 0;

      return object;
    };


    if(cachedPanel) {
      return Promise.resolve(onLoadItem(cachedPanel.clone()));
    }

    let mtl = require('./CornerPanelSet.mtl');
    let obj = require('./CornerPanelSet.obj');

    return loadObjWithMaterial(mtl, obj, '')
      .then(object => {
        cachedPanel = object;
        return onLoadItem(cachedPanel.clone())
      })
  }

};
