import { Catalog } from "react-planner";

let catalog = new Catalog();

import * as Areas from "./areas/**/planner-element.jsx";
import * as Lines from "./lines/**/planner-element.jsx";
import * as Holes from "./holes/**/planner-element.jsx";
import * as Items from "./items/**/planner-element.jsx";

for (let x in Areas) catalog.registerElement(Areas[x]);
for (let x in Lines) catalog.registerElement(Lines[x]);
for (let x in Holes) catalog.registerElement(Holes[x]);
for (let x in Items) catalog.registerElement(Items[x]);
catalog.registerCategory("Equipment", "Equipment", [
  Items.CombiClamp
]);
catalog.registerCategory("Posts", "Posts", [
  Items.boltdownoneside,
  Items.boltdowntwoside,
  Items.buildinoneside,
  Items.buildintwoside,

]);
catalog.registerCategory("panels", "Panels", [
  Items.CurvedPanel,
  Items.lambingjug4ft,
  Items.LambingJug5ft,
  Items.LambingJug6ft,
  Items.lambingjug8ft,
  Items.OpenPanel12,
  Items.OpenPanel18,
  Items.OpenPanel24,
  Items.SheetedPanel12,
  Items.SheetedPanel18,
  Items.SheetedPanel24,
  Items.InnerCorner,
  Items.OuterCorner,
  Items.CornerPanelSet,
  Items.TippingTrough10,
  Items.TippingTrough15,
]);
catalog.registerCategory("gates", "Gates", [
  Items.twopartgateinframe,
  Items.guillotineswing,
  Items.DraftSwingGate,
  Items.SheddingGate,
  Items.LiftAndSlide6ft,
  Items.LiftAndSlide8ft,
  Items.SwingGate09,
  Items.SwingGate12,
  Items.SwingGate18,
  Items.SwingGate24,
  Items.GuillotineGate2ft,
]);
catalog.registerCategory("animals", "Animals", [
  Items.cartoonSheep,
]);
catalog.registerCategory("doors", "Doors", [
  Holes.door,
  Holes.doorDouble,
  Holes.slidingDoor
]);
catalog.registerCategory("windows", "Windows", [
  Holes.window,
  Holes.sashWindow,
  Holes.venetianBlindWindow,
  Holes.windowCurtain
]);


export default catalog;
