import * as Three from 'three';
import {loadObjWithMaterial} from '../../utils/load-obj';

import React from 'react';
import convert from 'convert-units';

let cached3DTV = null;

export default {
  name: "CombiClamp",
  prototype: "items",

  info: {
    title: "Combi-Clamp",
    tag: ['furnishing', 'electronics'],
    description: "Combi-Clamp",
    image: require('./Combi-Clamp.png')
  },

  properties: {
    altitude: {
      label: "Altitude",
      type: "length-measure",
      defaultValue: {
        length: 0
      }
    }
  },

  render2D: function (element, layer, scene) {
    let width = {length: 3250, unit: 'mm'};
    let depth = {length: 420, unit: 'mm'};
    let width1 = {length: 1500, unit: 'mm'};
    let depth1 = {length: 500, unit: 'mm'};

    let newWidth = convert(width.length).from(width.unit).to(scene.unit);
    let newDepth = convert(depth.length).from(depth.unit).to(scene.unit);
    let newWidth1 = convert(width1.length).from(width1.unit).to(scene.unit);
    let newDepth1 = convert(depth1.length).from(depth1.unit).to(scene.unit);

    let angle = element.rotation + 90;

    let textRotation = 0;
    if (Math.sin(angle * Math.PI / 180) < 0) {
      textRotation = 180;
    }

    let style = {stroke: element.selected ? '#0096fd' : '#000', strokeWidth: "1px", fill: "#84e1ce"};
    let greystyle = {stroke: element.selected ? '#0096fd' : '#000', strokeWidth: "1px", fill: "#616160"};
    let lightgreystyle = {stroke: element.selected ? '#0096fd' : '#000', strokeWidth: "1px", fill: "#999999"};
    let arrow_style = {stroke: element.selected ? '#0096fd' : null, strokeWidth: "2px", fill: "#84e1ce"};

    return (
      <g transform={`translate(${-newWidth / 2},${-newDepth / 2})`}>
        <rect key="1" x="6" y="19" width={newWidth} height={newDepth} style={lightgreystyle}/>
        <rect key="7" x={newWidth/2.75} y="19" width={newWidth/1.5} height={newDepth} style={greystyle}/>
        <rect key="6" x="-9" y={11-newDepth1} width={newWidth1} height={newDepth1} style={style}/>
        <line key="2" x1={newWidth / 2} x2={newWidth / 2} y1={newDepth} y2={1.5 * newDepth} style={arrow_style}/>
        <line key="3" x1={.35 * newWidth} x2={newWidth / 2} y1={1.2 * newDepth} y2={1.5 * newDepth} style={arrow_style}/>
        <line key="4" x1={newWidth / 2} x2={.65 * newWidth} y1={1.5 * newDepth} y2={1.2 * newDepth} style={arrow_style}/>
        <text key="5" x="-95" y="-5" transform={`translate(${newWidth / 2}, ${newDepth / 1.5}) scale(1,-1) rotate(${textRotation})`}
              style={{textAnchor: "middle", fontSize: "11px"}}>
          {element.type}
        </text>
        <text key="8" x="-95" y="50" transform={`translate(${newWidth / 2}, ${newDepth / 1.5}) scale(1,-1) rotate(${textRotation})`}
              style={{textAnchor: "middle", fontSize: "11px"}}>
          Foot Pedal
        </text>
        <text key="9" x="60" y="-5" transform={`translate(${newWidth / 2}, ${newDepth / 1.5}) scale(1,-1) rotate(${textRotation})`}
              style={{textAnchor: "middle", fontSize: "11px"}}>
          Textured Rubber Ramp
        </text>
      </g>
    );
  },

  render3D: function (element, layer, scene) {
    let width = {length: 3524, unit: 'mm'};
    let depth = {length: 544, unit: 'mm'};
    let height = {length: 1000, unit: 'mm'};

    let onLoadItem = (object) => {

      let newWidth = convert(width.length).from(width.unit).to(scene.unit);
      let newHeight = convert(height.length).from(height.unit).to(scene.unit);
      let newDepth = convert(depth.length).from(depth.unit).to(scene.unit);

      let newAltitude = element.properties.get('altitude').get('length');

      if (element.selected) {
        let box = new Three.BoxHelper(object, 0x99c3fb);
        box.material.linewidth = 2;
        box.material.depthTest = false;
        box.renderOrder = 1000;
        object.add(box);
      }

      object.scale.set(newWidth / width.length, newHeight / height.length, newDepth / depth.length);

      // Normalize the origin of the object
      let boundingBox = new Three.Box3().setFromObject(object);

      let center = [
        (boundingBox.max.x - boundingBox.min.x) / 2 + boundingBox.min.x,
        (boundingBox.max.y - boundingBox.min.y) / 2 + boundingBox.min.y,
        (boundingBox.max.z - boundingBox.min.z) / 2 + boundingBox.min.z];

      object.position.x -= center[0];
      object.position.y -= center[1] - (boundingBox.max.y - boundingBox.min.y) / 2;
      object.position.z -= center[2];

      object.position.y += newAltitude;
      // rotate 90 degrees clockwise
      //object.rotation.y -= Math.PI / 2;
      // rotate 90 degrees clockwise around the x axis
      //object.rotation.z -= Math.PI / 2;
//
      return object;
    };


    if(cached3DTV) {
      return Promise.resolve(onLoadItem(cached3DTV.clone()));
    }

    let mtl = require('./Combi-Clamp.mtl');
    let obj = require('./Combi-Clamp.obj');

    return loadObjWithMaterial(mtl, obj, '')
      .then(object => {
        cached3DTV = object;
        return onLoadItem(cached3DTV.clone())
      })
  }

};
